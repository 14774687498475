function parseOrientationType() {
    var orientation, substr;
    try {
      orientation = screen.orientation.type;
      substr = orientation.substr(0, orientation.indexOf('-'));
    } catch (error) {
      orientation = 'string';
      substr = 'string';
    } finally {
      return substr;
    }
}

export {parseOrientationType};