window.widgets = window.widgets || {};

export const pointsOfInterest = (window.widgets.pointsOfInterest = () => {
  var $body = $(document.body),
    $widgets = $('[data-widget="points-of-interest"]'),
    toggleSpotsLang = (window.i18n && window.i18n.toggle_pois) || 'Toggle POIs';

  $widgets = $widgets.filter(function(index, el) {
    var $el = $(el),
      isTinyMCESelection = $el.closest('.mce-offscreen-selection').length;

    return !isTinyMCESelection;
  });

  $widgets.each(function(index, widget) {
    var $widget = $(widget);

    if ($widget.attr('data-initialized')) return;

    var data = $widget.data(),
      togglePointsPos = data.tglSpotPos || 'tr',
      coordinates = data.coordinates,
      positions = data.positions,
      titles = data.titles,
      $widgetPoints,
      html = '';

    if (!coordinates || !positions || !titles) return;

    coordinates = coordinates.split(',');
    positions = positions.split(',');
    titles = titles.split(':;');

    if (
      coordinates.length !== positions.length ||
      positions.length !== titles.length
    )
      return;

    html += [
      '<div class="toggle-spots toggle-spots_' + togglePointsPos + '">',
      '<span class="toggle-spots-title">' + toggleSpotsLang + '</span>',
      '</div>',
    ].join('');

    html +=
      '<img draggable="false" src="' +
      data.imageSrc +
      '" width="100%" alt="' +
      data.imageTitle +
      '"/>';

    $.each(coordinates, function(index, coord) {
      var xy = coord.split('_');

      html += [
        '<div class="point-wrapper not-visited ' +
          positions[index] +
          '" style="top:' +
          xy[1] +
          '%; left:' +
          xy[0] +
          '%;">',
        '<span class="point"></span>',
        '<div class="more-info">',
        '<span>' + titles[index] + '</span>',
        '<span class="close-info"></span>',
        '</div>',
        '</div>',
      ].join('');
    });

    $widget.html(html);

    $widgetPoints = $widget.find('.point');

    $widget
      .on('click', function() {
        $widgetPoints.removeClass('open');
      })
      .on('click', '.point-wrapper', function(event) {
        event.stopPropagation();
        var $pointWrapper = $(this),
          $point = $pointWrapper.find('.point');

        $widgetPoints.not($point).removeClass('open');

        $pointWrapper.removeClass('not-visited');
        $point.toggleClass('open');
      })
      .on('click', '.toggle-spots', function() {
        $widget.toggleClass('points-hidden');
      });

    $widget.attr('data-initialized', 1);
  });
});
