(function($, window, document, undefined) {
 
    'use strict';
 
    var defaults = {
        topside: false,
        topsideInit: false,
        topsideDestroy: false,
        topsideOnAfterSlide: false,
        topsideOnBeforeSlide: false
    };
 
    var topside = function(element) {

        this.core = $(element).data('lightGallery');
        this.$el = $(element);
        this.core.s = $.extend({}, defaults, this.core.s)
        if(this.core.s.topside){
            this.init();
        }
        return this;
    }
    topside.prototype.$box = [];
    topside.prototype.$boxWrapper = [];

    topside.prototype.init = function() {
    
        var _this = this,
            $outer = _this.core.$outer,
            // $skinHolder = $('<div class="skin-holder"></div>'),
            $topside = $('<div class="lg-topside-box"></div>'),
            $topsideWrapper = $('<div class="lg-topside-box-wrapper-without-apb"></div>'),
            $closeButton = $('<div class="lg-topside-box-close-button"></div>');

        let eachFiveSlide = false;

        if (eachFiveSlide) {
            $topsideWrapper.append($closeButton);
            $topsideWrapper.removeClass('lg-topside-box-wrapper-without-apb').addClass('lg-topside-box-wrapper');
        }
        
        // $topsideWrapper.append($skinHolder);
        $topside.append($topsideWrapper);
        $outer.addClass('lg-topside');
        // $outer.find('.lg').append($topside);
        $topside.insertBefore($outer.find('.lg-toolbar'));
        
        _this.$box = $topside;
        _this.$boxWrapper = $topsideWrapper;

        if(typeof _this.core.s.topsideInit === 'function'){
            _this.core.s.topsideInit.call(_this);
        }

        if(typeof _this.core.s.topsideOnAfterSlide === 'function'){
 
            _this.$el.on('onAfterSlide.lg.topside', function(event, prevIndex, index, fromTouch, fromThumb) {
                _this.core.s.topsideOnAfterSlide.call(_this, event, prevIndex, index, fromTouch, fromThumb);
            });
        }
        if(typeof _this.core.s.topsideOnBeforeSlide === 'function'){
            _this.$el.on('onBeforeSlide.lg.topside', function(event, prevIndex, index, fromTouch, fromThumb) {
                _this.core.s.topsideOnBeforeSlide.call(_this, event, prevIndex, index, fromTouch, fromThumb);
            });
        }
        $closeButton.on('click', function(){
            _this.hide();
        });
    };
    topside.prototype.addMarkHtmlSlide = function(){
        this.core.$outer.addClass('lg-html-slide');
    }
    topside.prototype.hideMarkHtmlSlide = function(){
        this.core.$outer.removeClass('lg-html-slide');
    }
    topside.prototype.addMarkAdSlide = function(){
        this.core.$outer.addClass('lg-custom-ad-slide');
    }
    topside.prototype.hideMarkAdSlide = function(){
        this.core.$outer.removeClass('lg-custom-ad-slide');
    }
    topside.prototype.hide = function(){
        this.core.$outer.addClass('lg-topside-off');
    }
    topside.prototype.show = function(){
        this.core.$outer.removeClass('lg-topside-off');
    }
    topside.prototype.destroy = function() {
        if(typeof this.core.s.topsideDestroy === 'function'){
            this.core.s.topsideDestroy.call(this);
        }
        this.$el.off('onBeforeSlide.lg.topside'); 
        this.$el.off('onAfterSlide.lg.topside'); 
    }

    $.fn.lightGallery.modules.topside = topside;
 
 
})(jQuery, window, document);