(function($, window, document, undefined) {
 
    'use strict';
 
    var defaults = {
        aside: false,
        asideInit: false,
        asideDestroy: false,
        asideOnAfterSlide: false
    };
 
    var aside = function(element) {

        this.core = $(element).data('lightGallery');
        this.$el = $(element);
        this.core.s = $.extend({}, defaults, this.core.s)
        if(this.core.s.aside){
            this.init();
        }
        return this;
    }
    aside.prototype.$box = [];
    aside.prototype.$boxWrapper = [];

    aside.prototype.init = function() {
        var _this = this,
            $outer = _this.core.$outer,
            $aside = $('<div class="lg-aside-box"></div>'),
            $asideWrapper = $('<div class="lg-aside-box-wrapper"></div>');

        $aside.append($asideWrapper);
        $outer.addClass('lg-aside');
        $outer.find('.lg').append($aside);
        
        _this.$box = $aside;
        _this.$boxWrapper = $asideWrapper;

        if(typeof _this.core.s.asideInit === 'function'){
            _this.core.s.asideInit.call(_this);
        }

        if(typeof _this.core.s.asideOnAfterSlide === 'function'){       
            _this.$el.on('onAfterSlide.lg.aside', function(event, prevIndex, index, fromTouch, fromThumb) {  
                _this.core.s.asideOnAfterSlide.call(_this, event, prevIndex, index, fromTouch, fromThumb);
            });
        }
    };

    aside.prototype.destroy = function() {
        if(typeof this.core.s.asideDestroy === 'function'){
            this.core.s.asideDestroy.call(this);
        }
        this.$el.off('onAfterSlide.lg.aside'); 
    }

    $.fn.lightGallery.modules.aside = aside;
 
 
})(jQuery, window, document);