window.widgets = window.widgets || {};

export const compareImages = (window.widgets.compareImages = () => {
  var $window = $(window),
    $body = $(document.body),
    $widgets = $('[data-widget="compare-images"]');

  $widgets = $widgets.filter(function (index, el) {
    var $el = $(el),
      isTinyMCESelection = $el.closest('.mce-offscreen-selection').length;

    return !isTinyMCESelection;
  });

  $widgets.each(function (widgetIndex, widget) {
    var $widget = $(widget),
      $widgetWrapper = $('.compare-images__wrapper', $widget).length
        ? $('.compare-images__wrapper', $widget)
        : $widget;

    if ($widget.attr('data-initialized')) return;

    var $imageWrappers = $widget.find('[data-widget="image"]'),
      $leftIimg = $imageWrappers.eq(0),
      $rightIimg = $imageWrappers.eq(1),
      rightIimg = $rightIimg[0],
      isDragging = false,
      isVerticalSplit = $widget.data('split-vertical'),
      widgetWidth,
      widgetHeight,
      widgetOffsetsX,
      widgetOffsetsY,
      animated = false,
      hasBeenTouched = false;

    var $resizer = $('<div class="resizer"><span></span></div>'),
      resizer = $resizer[0],
      resizerHalfSize;

    if (isVerticalSplit) {
      widgetWidth = $widgetWrapper.width();
      resizerHalfSize = $resizer.width() / 2;
    } else {
      widgetHeight = $widgetWrapper.height();
      resizerHalfSize = $resizer.height() / 2;
    }

    $widgetWrapper.append($resizer);

    $body.on('mouseup touchend', function () {
      isDragging = false;
    });

    // this prevent animation and error in WYSIWYG editor, where this method is not available
    if ($.throttle && typeof $.throttle === 'function') {
      $window.on(
        'scroll.compare-images' + widgetIndex,
        $.throttle(1000, function () {
          if (animated) return;

          var widgetTop = $widgetWrapper.offset().top,
            widgetHeight = $widgetWrapper.height(),
            widgetHeightPart = widgetHeight / 2,
            widgetBottom = widgetTop + widgetHeight,
            windowHeight = window.innerHeight,
            scrollTop = $window.scrollTop();

          // if we higher then top part of widget
          // or lower then bottom part
          if (
            scrollTop + windowHeight < widgetTop + widgetHeightPart ||
            scrollTop > widgetBottom - widgetHeightPart
          )
            return;

          animated = true;

          if (isVerticalSplit) {
            resizer.style.WebkitAnimation =
              'slide-bounce-ease-left 2s ease-out';
            resizer.style.animation = 'slide-bounce-ease-left 2s ease-out';

            rightIimg.style.WebkitAnimation =
              'slide-bounce-ease-width 2s ease-out';
            rightIimg.style.animation = 'slide-bounce-ease-width 2s ease-out';
          } else {
            resizer.style.WebkitAnimation = 'slide-bounce-ease-top 2s ease-out';
            resizer.style.animation = 'slide-bounce-ease-top 2s ease-out';

            rightIimg.style.WebkitAnimation =
              'slide-bounce-ease-height 2s ease-out';
            rightIimg.style.animation = 'slide-bounce-ease-height 2s ease-out';
          }
        }),
      );
    }

    function onAnimationEnd() {
      animated = false;
      resizer.style.WebkitAnimation = 'none';
      resizer.style.animation = 'none';

      rightIimg.style.WebkitAnimation = 'none';
      rightIimg.style.animation = 'none';
    }

    resizer.addEventListener('webkitAnimationEnd', onAnimationEnd);
    resizer.addEventListener('animationend', onAnimationEnd);

    $body
      // .on('mouseleave', function(event) {
      //     isDragging = false;
      // })
      .on('mousemove touchmove', function (event) {
        if (!isDragging) return;

        var val, valPercents;

        if (isVerticalSplit) {
          val =
            (event.originalEvent.touches
              ? event.originalEvent.touches[0].pageX
              : event.originalEvent.pageX) - widgetOffsetsX;

          if (val < 0) {
            val = 0;
          } else if (val + resizerHalfSize > widgetWidth) {
            val = widgetWidth - resizerHalfSize;
          }

          valPercents = (val / widgetWidth) * 100;

          $resizer.css('left', valPercents + '%');
          $rightIimg.width(100 - valPercents + '%');
        } else {
          val =
            (event.originalEvent.touches
              ? event.originalEvent.touches[0].pageY
              : event.originalEvent.pageY) - widgetOffsetsY;

          if (val < 0) {
            val = 0;
          } else if (val + resizerHalfSize > widgetHeight) {
            val = widgetHeight - resizerHalfSize;
          }

          valPercents = (val / widgetHeight) * 100;

          $resizer.css('top', valPercents + '%');
          $rightIimg.height(valPercents + '%');
        }
      });

    $resizer
      .on('click', function (event) {
        event.stopPropagation();
      })
      .on('mousedown touchstart', function (event) {
        event.preventDefault();
        isDragging = true;

        if (!hasBeenTouched) {
          hasBeenTouched = true;

          $window.off('scroll.compare-images' + widgetIndex);
          resizer.removeEventListener('animationend', onAnimationEnd);
          resizer.removeEventListener('webkitAnimationEnd', onAnimationEnd);
        }

        if (isVerticalSplit) {
          widgetWidth = $widget.width();
          widgetOffsetsX = $widget.offset().left;
        } else {
          widgetHeight = $widget.height();
          widgetOffsetsY = $widget.offset().top;
        }
      });
    // .on('mouseup touchend', function(event) {
    //     isDragging = false;
    // });

    $widget.attr('data-initialized', 1);
  });
});
