"use strict";
import device from 'current-device';
$.fn.stickySidebar = function (options) {

    var scrollY = 0, $elements = this.not('[data-ready="true"]'), $window = $(window), $mass = $([]), calculation = $window.innerWidth() > 1023;

    var $headerElement = $('.m1-header-main'),
        $direction = $('body').data('rtl'),
        scrollDirection = 1, // 1 = down, -1 = up;
        times;

    $window.on('mousewheel DOMMouseScroll', function(event){
        var delta = event.originalEvent.detail || event.originalEvent.deltaY;

        scrollDirection = delta < 0 ? -1 : 1;

    })

    $headerElement.on('transitionstart', function(event) {
        if (!calculation) return;

        if  (scrollY <= 60) return;

        if (scrollDirection == 1){
            $mass.addClass('removeTransiction');
        }
    });

    options = $.extend({
        threshold: [0],
        hederselector: '.m1-header-main'
    },options);

    var isheadervisible = true;

    function onEntry(entry) {
        entry.forEach( function(change) {
            var $entry = $(change.target).find($elements);

            if(change.isIntersecting) {
                $mass = $mass.add($entry);
                return;

            } else {
                var indexTarget = $mass.index($entry);

                $mass = $mass.filter(function(index) {
                    return index != indexTarget;;
                });

            }
        });

        recalculateLimits($mass);
        onScroll($mass);
    }

    var observerOptions = {
        threshold: options.threshold
    };

    var observer = new IntersectionObserver(onEntry, observerOptions);

    $elements.each(function(index, el) {
        observer.observe($(el).closest(options.containerSelector).get(0));
    });

    function headerOnEntry(entry){
        isheadervisible = entry[0].isIntersecting;

        recalculateLimits($mass);
        onScroll($mass);
    }

    var headerobserver = new IntersectionObserver(headerOnEntry, observerOptions);

    headerobserver.observe($(options.hederselector).get(0));

    var offtop = function(){
        var $body = $('body');
        var offsett = 0;

        if (isheadervisible){
            offsett = 60;
        }

        return offsett;

    }

    var recalculateLimits = function($elements) {

        if (!calculation) return;

        $elements.each(function(index, el) {
            var $this = $elements.eq(index),
                $container = options.containerSelector ? $this.closest(options.containerSelector) : $(document.body),
                offset = $this.offset(),
                containerOffset = $container.offset().top - offtop();


            var pad = $.extend({
              top: 0,
              bottom: 0
            }, options.padding || {});

            if (options.limitedScroll) {
                if ($container.height()/$this.outerHeight() >= 3) {
                    times = Math.round($container.height()/$this.outerHeight()) - 1;
                } else {
                    times = 1;
                }
            } else {
                times = 1;
            }

            $this.data("stickySidebar", {
                pad: pad,
                from: (options.containerSelector ? containerOffset : offset.top) - pad.top,
                to: containerOffset + $container.height() - times*$this.outerHeight() - pad.bottom,
                end: containerOffset + $container.height(),
                parentTop: containerOffset
            });
        });
    };

    var onScroll = function ($elems) {

        scrollY = $window.scrollTop();
        // don't remove this case, fix for thewall
        if (device.type === 'desktop' && !calculation ) {
            $elements
              .not($mass)
              .removeAttr('style');
        }
        // fix for MacOS kinetic scroll, when elements stay "fixed"
        // when they should be "static"
         else if (device.type === 'desktop') {
            $elements
            .not($mass)
            .css({
                position: 'absolute',
                transform: 'translate3d(0px, 0px, 0px)',
                transition: 'transform 0s ease',
            });
        } else if (device.type === 'tablet') {
            $elements
            .not($mass)
            .css({
                position: 'static',
                transform: 'translate3d(0px, 0px, 0px)',
                transition: 'transform 0s ease',
            });
        } else if (device.type === 'mobile' && calculation) {
            $elements
            .not($mass)
            .css({
                position: 'absolute',
                transform: 'translate3d(0px, 0px, 0px)',
                transition: 'transform 0s ease',
            });
        } else if (device.type === 'mobile' && !calculation) {
            $elements
            .not($mass)
            .css({
                position: 'static',
                transform: 'translate3d(0px, 0px, 0px)',
                transition: 'transform 0s ease',
            });
        } else {
            $elements
            .not($mass)
            .css({
                position: 'absolute',
                transform: 'translate3d(0px, 0px, 0px)',
                transition: 'transform 0s ease',
            });
        }


        $elems.each(function(index, el) {
            var $this = $elems.eq(index),
                data  = $this.data("stickySidebar"),
                rangeoffset = offtop(),
                topsidebar = $headerElement.outerHeight(),
                offsetCont = $this.closest(options.containerSelector)[0].getBoundingClientRect().top,

                resultOffSide = topsidebar - offsetCont;
                var heigthContSidebar = $this.outerHeight(),
                    heigthContParent = $this.parent().outerHeight();

                if (heigthContSidebar > heigthContParent){
                    $($this.closest(options.containerSelector)[0]).css("min-height", heigthContSidebar);
                }

            // fix sticky element on scroll when he has position "fixed"
            if (!calculation) {
                if ($direction) {
                    $this.css({
                        position: '',
                        left: '',
                        top: '',
                        transform: '',
                        transition: ''
                    });
                } else {
                    $this.css({
                        position: '',
                        right: '',
                        top: '',
                        transform: '',
                        transition: ''
                    });
                }
                return;
            }
            if (scrollDirection == -1){
                $this.removeClass('removeTransiction');
            }

            var from = data.from - data.pad.bottom,
                to = data.to - data.pad.top;
            if (from + $this.outerHeight() > data.end) {
                $this.css('position', 'absolute');
                return;
            }

            if (from < scrollY && to > scrollY) {


                var $container =  $this.closest(options.containerSelector),
                    position = $this.css('position');

                    if (times > 3 && !options.infiniteScrollLoadedArticle) {
                        $this.removeClass('no-transform');
                        $('.adv-should-fixed > div > div').css({
                            position: 'static',
                        })

                        $('.adv-should-fixed > div').css({
                            height: 'auto',
                        })
                    }


                    if ($direction) {
                        $this.css({
                            left: ($window.width() - $container.width())/2,
                            top: data.pad.top,
                            transform: 'translate3d(0,'+rangeoffset+'px'+',0)',
                            position: 'fixed',
                            transition: resultOffSide < topsidebar || position != 'fixed' ? 'transform 0s ease' : 'transform 0.5s ease'
                        }).addClass('ie_transform');
                    } else {
                        $this.css({
                            right: ($window.width() - $container.width())/2,
                            top: data.pad.top,
                            transform: 'translate3d(0,'+rangeoffset+'px'+',0)',
                            position: 'fixed',
                            transition: resultOffSide < topsidebar || position != 'fixed' ? 'transform 0s ease' : 'transform 0.5s ease'
                        }).addClass('ie_transform');
                    }


            } else if (scrollY >= to) {

                if (times > 3 && !options.infiniteScrollLoadedArticle) {
                    var $container =  $this.closest(options.containerSelector);
                    var windowScrollPosition = $(window).scrollTop();
                    var secondAdvBlockPosition = $('.sidebar').offset().top + $('.sidebar').outerHeight();
                    var sidebarHeight = $('.content-area-box').offset().top + $('.content-area-box').outerHeight();
                    var fixedBanner = $('.adv-should-fixed > div > div');
                    var parentFixedBanner = $('.adv-should-fixed > div');
                    var fixedAdvBlockPosition = fixedBanner.length > 0 ? fixedBanner.offset().top + fixedBanner.outerHeight() : null;

                    fixedBanner.css({
                        position: 'fixed',
                        top: '60px',
                        right: ($window.width() - $container.width())/2,
                    });

                    parentFixedBanner.css({
                        height: fixedBanner.outerHeight()
                    });

                    sidebarHeight - fixedBanner.outerHeight() < fixedAdvBlockPosition ?
                            fixedBanner.css({opacity: '0', 'pointer-events': 'none'}) :
                            fixedBanner.css({opacity: '1', 'pointer-events': 'all'});

                    if (secondAdvBlockPosition > windowScrollPosition) {

                        fixedBanner.css({
                            position: 'static',
                        });
                        parentFixedBanner.css({
                            height: 'auto'
                        });
                    }
                }


                if ($direction) {
                    $this.css({
                        left: '0',
                        top: to - data.parentTop + data.pad.top,
                        position: 'absolute',
                        transform: 'translate3d(0px, 0px, 0px)',
                        transition: 'transform 0s ease'
                    });
                } else {
                    $this.css({
                        right: '0',
                        top: to - data.parentTop + data.pad.top,
                        position: 'absolute',
                        transform: 'translate3d(0px, 0px, 0px)',
                        transition: 'transform 0s ease'
                    });
                }

                if (times > 3) {
                    $this.addClass('no-transform')
                }

                var heigthContSidebar = $this.outerHeight(),
                    heigthContParent = $this.parent().outerHeight();

                if (heigthContSidebar > heigthContParent){
                    $($this.closest(options.containerSelector)[0]).css("min-height", heigthContSidebar);
                }
            } else {
                if ($direction) {
                    $this.css({
                        position: 'absolute',
                        left: '0',
                        transform: 'translate3d(0px, 0px, 0px)',
                        transition: 'transform 0s ease',
                    });

                } else {
                    $this.css({
                        position: 'absolute',
                        right: '0',
                        transform: 'translate3d(0px, 0px, 0px)',
                        transition: 'transform 0s ease',
                    });
                }

                if (heigthContSidebar > heigthContParent){
                    $($this.closest(options.containerSelector)[0]).css("min-height", heigthContSidebar);
                }
            }
        });
    };
    $window.on('resize', function(){
        calculation = $window.innerWidth() > 1023;
    });

    $window.on('scroll resize',function(){
        recalculateLimits($mass);
        onScroll($mass);
    });

    $elements.attr('data-ready', true);

    return this;
  };