/*####### cmp-bubbles.js - v1.1 - 26.04.2018 #######*/

export const cmpBubbles = {
    config: {
        selector: 'body',
        error: false,
        ajaxGlobal: true,
        minWidth: 0
    },
    init: function(config){

        if(config && config.constructor === Object){
            for(var i in config){
                this.config[i] = config[i];
            }
        }
        this.listener();
        return this;
    },
    options: {},
    groupName: false,
    $element: [],
    $target: [],
    posX: 0,
    posY: 0,
    active: false,
    timeout: null,
    hidetimeout: 0,
    listener: function(){
        var _this = this;
        $(document).on('touchstart', function(event){
            if(_this.config.minWidth){
                if(document.documentElement.clientWidth < _this.config.minWidth){
                    return;
                }
            }
            if(_this.active){
                $('[data-bubbles].active').trigger('mouseleave');
            }
        });
        $(this.config.selector)
        .on('mousemove', '[data-bubbles]', function(event){
            if(_this.config.minWidth){
                if(document.documentElement.clientWidth < _this.config.minWidth){
                    return;
                }
            }
            _this.getPosition.call(_this, event);
            var data = $(this).data();
            if('move' in data && data.move !== false){
               _this.setPosition.call(_this); 
            }else{
                if(event.target == this){
                    if(_this.hidetimeout >= 15){
                        $(this).trigger('mouseleave');
                        _this.hidetimeout = 0;
                    }else{
                        _this.hidetimeout++;
                    }
                }
            }
        })
        .on('mouseenter touchstart mousedown', '[data-bubbles-target]', function(event){
            event.stopPropagation();
        })
        .on('mouseenter.bubbles touchstart.bubbles mousedown.bubbles', '[data-bubbles]', function(event){
            if(_this.config.minWidth){
                if(document.documentElement.clientWidth < _this.config.minWidth){
                    return;
                }
            }
            event.preventDefault();
            _this.hidetimeout = 0;
            var delay = 0;
            if(event.type == 'touchstart'){
                if(_this.active){
                    $('[data-bubbles].active').trigger('mouseleave');
                }
                $(_this.config.selector).off('mousedown.bubbles');
            }
            _this.active = true;
            
            var $this = $(this),
                data = $this.data(),
                groupName = data.bubbles || false;
            
            if($this.is('.disabled') || $this.is('.wait')){
                return;
            }
            if(!data.propagation){
                event.stopPropagation();
            }

            if(groupName){
                if(groupName != _this.groupName){
                    _this.groupName = groupName;
                    _this.options = {};
                    var $group = _this.$getGroup(groupName);
                    if($group.length){
                        $group.each(function(){
                            _this.setOptions($(this).data());
                        });
                    }
                }
            }else{
                _this.groupName = false;
                _this.options = {};
                _this.setOptions(data);
            }
            if(event.type == 'mouseenter'){
                delay = _this.options.delay == undefined ? 300 : !_this.options.delay ? 0 : _this.options.delay*1000;
            }
            if(data.extraParams){
                if(data.extraParams.constructor === Object){
                    _this.options.extraParams = data.extraParams;
                }else{
                    _this.options.extraParams = _this.extraParamsParse(data.extraParams);
                }
            }else if(_this.options.extraParams){
                delete _this.options.extraParams;
            }
            if(data.value !== undefined){
                _this.options.value = data.value;
            }else if(_this.options.value){
                delete _this.options.value;
            }
            if(data.viewCache !== undefined){
                _this.options.viewCache = data.viewCache;
            }else if(_this.options.viewCache){
                delete _this.options.viewCache;
            }
            if(data.title !== undefined){
                _this.options.title = data.title;
            }else if(_this.options.title){
                delete _this.options.title;
            }
            _this.$element = $this,
            _this.$target = $this.find('[data-bubbles-target]');
            var el = this;

            clearTimeout(_this.timeout);
            _this.timeout = setTimeout(function(){
                if(_this.options.url){
                    if(!_this.options.viewCache){
                        _this.request(data, el);
                    }else{
                        _this.success(data, el);
                    }
                }else{
                    _this.success(data, el);
                } 
            }, delay);
            _this.getPosition.call(_this, event);
        })
        .on('mouseleave', '[data-bubbles]', function(event){
            if(_this.config.minWidth){
                if(document.documentElement.clientWidth < _this.config.minWidth){
                    return;
                }
            }
            clearTimeout(_this.timeout);
            _this.hide();
            if(_this.$target.length){
                _this.$target.removeAttr('style');
            }
            _this.$element = [];
            _this.$target = [];
            //_this.groupName = false;
            _this.active = false;
        });
    },
    extraParamsParse: function(params){
        if(params){
            if ( params.indexOf( '{' ) <0 ){
                params = "{" + params + "}";
            }
            return eval("(" + params + ")");
        }
        return false;
    },
    request: function(eldata, el){
        var _this = this,
            data = this.options,
            params = {},
            $el = $(el);
        if(data.extraParams && data.extraParams.constructor === Object){
            params = data.extraParams;
        }
        if(this.options.value !== undefined){
            if(data.name){
                params[data.name] = this.options.value;
            }else{
                params['value'] = this.options.value;
            }
        }
        var ajaxOptions = {
            dataType: 'json',
            url: data.url,
            cache: data.cache !== undefined ? data.cache : true,
            data: params,
            type: data.method || 'GET',
            global: _this.config.ajaxGlobal,
            beforeSend: function(jqXHR, settings){
                $el.addClass('wait');
                var r = _this.beforeSend.call(_this, eldata, el, jqXHR, settings);
                if(r === false){
                    $el.removeClass('wait');
                }
                return r;
            },
            success: function(response, textStatus, jqXHR){
                $el.removeClass('wait');
                if(response && response.constructor === Object){
                    _this.success.call(_this, eldata, el, response.status, response, textStatus, jqXHR);
                        if(data.viewCache == undefined){
                            _this.$element.data('view-cache', true);
                        }
                }else{
                    _this.error.call(_this, eldata, el, response, textStatus, jqXHR);
                }
            },
            error: function(jqXHR, textStatus){
                $el.removeClass('wait');
                _this.error.call(_this, eldata, el, {}, textStatus, jqXHR);
            }
        }

        $.ajax(ajaxOptions);
    },
    error: function(data, el, response, textStatus, jqXHR){
        if(this.config.error && typeof this.config.error == 'function'){
            this.config.error.call(this, data, el, response, textStatus, jqXHR);
        }
    },
    beforeSend: function(data, el, jqXHR, settings){
        if(this.config.beforeSend && typeof this.config.beforeSend == 'function'){
            this.config.beforeSend.call(this, data, el, jqXHR, settings);
        }
    },
    getPosition: function(event){
        if(event.type == 'touchstart'){
            var touches = event.originalEvent.changedTouches[0];
            this.posX = touches.pageX - window.pageXOffset;
            this.posY = touches.pageY - window.pageYOffset;
        }else{
            this.posX = event.pageX - window.pageXOffset;
            this.posY = event.pageY - window.pageYOffset;            
        }
    },
    setPosition: function(){
        if(this.$target.length){
            var rect = this.$target[0].getBoundingClientRect(),
                winWidth = document.documentElement.clientWidth,
                winHeight = document.documentElement.clientHeight,
                pY = this.posY + 1,
                pX = this.posX + 1;
            this.$target.attr('data-pos', 1);
            if(this.posY + rect.height > winHeight){
                pY = this.posY - this.$target.outerHeight() - 1;
                this.$target.attr('data-pos', 3);
            }
            if(this.posX + rect.width > winWidth){
                pX = this.posX - this.$target.outerWidth() - 1;
                this.$target.attr('data-pos', 2);
            }
            if(this.posY + rect.height > winHeight && this.posX + rect.width > winWidth){
                this.$target.attr('data-pos', 4);
            }
            this.$target.css('top', pY + 'px');

            this.$target.css('left', pX + 'px');
        }
    },
    show: function(){
        if(!this.active){
            return;
        }
        var _this = this;
        if(this.$element.length){
            this.$element.addClass('active');
        }
        if(this.$target.length){
            this.$target.removeClass('disabled').addClass('enabled');
            setTimeout(function(){
                if(_this.$target.length){
                    _this.$target.addClass('show');
                }
            },1);
        }
    },
    hide: function(){
        if(this.$element.length){
            this.$element.removeClass('active');
        }
        if(this.$target.length){
            this.$target.removeClass('enabled show').addClass('disabled');
        }
    },
    build: function(el, content){
        this.$target = $('<span data-bubbles-target class="disabled"></span>').html('<span class="bubbles-box">' + content + '</span>').appendTo(el);
    },
    success: function(data, el, status, response, textStatus, jqXHR){
        var content = response && response.html ? response.html : data.title || '';

        if(!this.$target.length){
            this.build.call(this, el, content);
        }

        this.setPosition.call(this);
        this.show.call(this);
    },
    setOptions: function(data){
        for(var i in data){
            if(i in this.options || i == 'value' || i == 'extraParams' || i == 'viewCache'){
                continue;
            }
            this.options[i] = data[i];
        }
    },
    $getGroup: function(groupName, root){
        return $('[data-bubbles="' + groupName + '"]', root || document.body);
    }
}
