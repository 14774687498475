window.widgets = window.widgets || {};

export const faqAccordeon = (window.widgets.faqAccordeon = () => {
  const $widgets = $('.widget_faq');

  $widgets.each(function (index, widget) {
    if($(widget).attr('data-initialized') || $(widget).attr('data-noinit')) return;

    const $accordions = $(widget).find('.widget_faq_question');

    $accordions.each(function (index, accordion) {
      const $accordion = $(accordion);
      if (!$accordion.hasClass("active")) {
        const accordionContent = $accordion.next();
        accordionContent.slideUp(0);
      }else{
        $accordion.addClass('active');
      }

      $accordion.on('click', function (ev) {
        $accordion.toggleClass('active');
        const accordionContent = $accordion.next();
        if ($accordion.hasClass("active")) {
          accordionContent.slideDown(300);
        } else {
          accordionContent.slideUp(300);
        }
      })
    })
    $(widget).attr('data-initialized', 1);
  })
});