import device from 'current-device';
import {parseOrientationType} from '../parse-orientation-type';

(function($, window, document, undefined) {
  'use strict';
  var defaults = {
    additional: [],
    additionalWillRender: false,
    additionalDidRender: false,
  };

  var AdditionalHTML = function(element) {
    this.core = $(element).data('lightGallery');
    this.$el = $(element);
    this.core.s = $.extend({}, defaults, this.core.s);

    if (this.core.s.additional) {
      this.init();
    }

    return this;
  };

  AdditionalHTML.prototype.init = function() {
    // TODO
    var _this = this;
    var $outer = _this.core.$outer;
    var $parentBox = $(`<div class="lg-additional"></div>`);
    var $thumbElement = $('.lg-toogle-thumb.lg-icon');
    var $thumbElementList = $('.lg-thumb-outer');

    if (this.core.s.additional.length) {
      this.core.s.additional.forEach(item => {
        var $element = $('<div class="additional-item"></div>');
        var $wrapper = $('<div class="additional-item-wrapper"></div>');

        if (item.className && item.className.length) {
          $element.addClass(item.className);
        }

        $wrapper.append(item.html);
        $element.append($wrapper);
        $parentBox.append($element);

        if (device.type === 'mobile') {
          const targetElement = document.querySelector('.lg-outer');
          // window.addEventListener('orientationchange', function(e) {
          //   const isPortrait = window.innerHeight > window.innerWidth;
          //   console.log(isPortrait);
          //   if (isPortrait && $outer.hasClass('lg-topside-off') && !$outer.hasClass('lg-html-slide')) {
          //     $outer.removeClass('lg-topside-off');
          //   }
          // });

          const resizeObserver = new ResizeObserver(entries => {
            if (window.innerHeight > window.innerWidth && $outer.hasClass('lg-topside-off') && !$outer.hasClass('lg-html-slide') && !$outer.hasClass('lg-custom-ad-slide')) {
              $outer.removeClass('lg-topside-off');
            }
          });

          resizeObserver.observe(targetElement);
        }
      });
    }

    if (typeof _this.core.s.additionalWillRender === 'function') {
      _this.core.s.additionalWillRender.call(_this);
    }

    $outer.find('.lg').append($parentBox);
    $outer.addClass('lg-with-additional');

    if (typeof _this.core.s.additionalDidRender === 'function') {
      _this.core.s.additionalDidRender.call(_this);
    }
  };

  AdditionalHTML.prototype.destroy = function() {};

  $.fn.lightGallery.modules.AdditionalHTML = AdditionalHTML;
})(jQuery, window, document);
