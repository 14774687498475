import '../hammer';
import device from 'current-device';
import {parseOrientationType} from '../parse-orientation-type';

(function($, window, document, undefined) {
  'use strict';

  var defaults = {
    customContentContainerCls: ''
  };

  var CustomContent = function(element) {
    // You can access all lighgallery variables and functions like this.
    this.core = $(element).data('lightGallery');

    this.$el = $(element);
    this.core.s = $.extend({}, defaults, this.core.s);

    this.init();

    return this;
  };

  CustomContent.prototype.init = function() {
    var me = this;
    //this.$outer = $(".lg-outer");
    this.$outer = document.getElementsByClassName('lg-outer')[0];
    this.$apb = $('.lg-topside-box-wrapper-without-apb .apb');
    var mc = new Hammer(me.$outer);
    mc.options.domEvents = true;

    me.$el.on('onAferAppendSlide.lg.customContent', function(event, index) {

      var item = me.core.$items[index];
      
      if (item.htmlSlide) {
        me.insertContent(index, item.src, 'additional');
        //return;
      } else if (item.adSlide) {
        me.insertContent(index, item.src, 'ads');
        me.$outer.classList.add('lg-ad');
      } else {
        return;
      }
      
    });

    mc.on("tap", function(event) {
      if (event.target.classList.contains('lg-image')) {
        if (parseOrientationType() === 'landscape' || window.innerWidth > window.innerHeight && window.innerWidth < 813) {
          me.$outer.classList.toggle("lg-hide-some-elements");
        }
      }
    });

    $(window).on('orientationchange', function(event) {
      $(window).one('resize', function() {
        if (parseOrientationType() === 'landscape' || (window.innerWidth > window.innerHeight && window.innerWidth < 813)) {
          me.$apb.css('display', 'none');
          // me.$contentWrapper.css('display', 'none');
        } else {
          me.$apb.css('display', '');
          // me.$contentWrapper.css('display', '');
        }
      });
      //me.$outer.removeClass('lg-hide-some-elements');
      me.$outer.classList.remove('lg-hide-some-elements');
    });
  };

  CustomContent.prototype.insertContent = function(index, html, marker) {
    var $slide = this.core.$slide.eq(index),
        $slideImgWrap = $slide.find('.lg-img-wrap'),
        $customContentWrap;

    $slide.addClass('lg-complete');

    if (marker == 'additional') {
      $customContentWrap = $('<div class="lg-custom-content"></div>');

      $customContentWrap
      .html(html)
      .addClass(this.core.s.customContentContainerCls);

      $slideImgWrap
      .empty()
      .append($customContentWrap);

      this.$el.trigger('onCustomContentReady.lg', [$customContentWrap]);
    } else if (marker == 'ads') {

      $customContentWrap = $('<div class="lg-custom-ads"></div>');

      $customContentWrap
      .html(html)

      $slideImgWrap
      .empty()
      .append($customContentWrap);

      if ($slideImgWrap.get(0).nextSibling) $slideImgWrap.get(0).nextSibling.remove();
     
      this.$el.trigger('onCustomContentReady.lg', [$customContentWrap]);
    }

  };


  /**
    * Destroy function must be defined.
    * lightgallery will automatically call your module destroy function 
    * before destroying the gallery
    */
  CustomContent.prototype.destroy = function() {
    this.$el.off('onAferAppendSlide.lg.customContent');
    if (!this.core.s.hash) return;

    this.$el.off('.lg.customcontent');
  }

  $.fn.lightGallery.modules.customcontent = CustomContent;
  
})(jQuery, window, document);