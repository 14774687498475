import { cmpButtons } from './cmp-buttons';
import { disableScroll } from '../utils/fn';

/*####### cmp-buttons-utils.js - v1.098 - 26.09.2017 #######*/
export const cmpButtonsUtils = {
  responseAlertMessages: function(response, type, delay) {
    var type = type || (response.status ? 'message' : 'error'),
      delay = delay || (response.status ? 10 : 20);
    if (response.message) {
      cmpTrayAlert.show(response.message, type, delay);
    } else if (response.messages && response.messages.length) {
      var msg = '<ul>';
      for (var i = 0, c = response.messages.length; i < c; i++) {
        msg += '<li>' + response.messages[i] + '</li>';
      }
      msg += '</ul>';
      cmpTrayAlert.show(msg, type, delay);
    }
  },
  toggleButtons: function(element, data) {
    if (data.groupToggle !== undefined || data.multiGroupToggle !== undefined) {
      if (this.toggleButtons) {
        this.toggleButtons(element, data);
      }
    }
  },
  requestConfirm: function(element) {
    var $this = $(element),
      data = $this.data(),
      ready = data.ready;
    if (!ready) {
      cmpDialog.confirm(data.confirmMessage || 'Are you sure?', null, function(
        value
      ) {
        if (value) {
          $this.data('ready', 1);
          $this.trigger('click');
        }
      });
      return false;
    }
    $this.data('ready', 0);
  },
  redirect: function(data, response) {
    if ('goToUrl' in window) {
      var URL = false;
      if ('url' in response) {
        URL = response.url;
      } else if ('redirectUrl' in data) {
        URL = data.redirectUrl;
      } else if (
        'redirectSuccessUrl' in data &&
        'status' in response &&
        response.status
      ) {
        URL = data.redirectSuccessUrl;
      } else if (
        'redirectFailureUrl' in data &&
        'status' in response &&
        !response.status
      ) {
        URL = data.redirectFailureUrl;
      }
      if (URL !== false) {
        if (URL && URL.length) {
          goToUrl(URL);
        } else {
          goToUrl(window.location.href, null, {
            data: { preventScrolling: '' },
          });
        }
      }
    }
  },
  collectSelectedValues: function(data, element) {
    if (data && data.value) {
      var $selected = $(
        '[data-button="' + data.value + '"].selected:not([data-id])'
      );
      if ($selected.length) {
        var values = [];
        $selected.each(function() {
          var data = $(this).data();
          if ('value' in data) {
            values.push(data.value);
          }
        });
        if (values.length) {
          if (this.options && 'value' in this.options) {
            this.options.value = values;
          }
        }
      }
    }
  },
  buttons: {
    statusTrue: function(data, element, response, textStatus, jqXHR) {
      cmpButtonsUtils.toggleButtons.call(this, element, data);
      cmpButtonsUtils.responseAlertMessages(response);
      cmpButtonsUtils.redirect(data, response);
    },
    statusFalse: function(data, element, response, textStatus, jqXHR) {
      cmpButtonsUtils.responseAlertMessages(response, 'error', 30);
      cmpButtonsUtils.redirect(data, response);
    },
    request: {
      statusTrue: function(data, element, response, textStatus, jqXHR) {
        cmpButtonsUtils.buttons.statusTrue.call(
          this,
          data,
          element,
          response,
          textStatus,
          jqXHR
        );
      },
      statusFalse: function(data, element, response, textStatus, jqXHR) {
        cmpButtonsUtils.buttons.statusFalse.call(
          this,
          data,
          element,
          response,
          textStatus,
          jqXHR
        );
      },
    },
    requestC: {
      beforeSend: function(data, element) {
        return cmpButtonsUtils.requestConfirm(element);
      },
      statusTrue: function(data, element, response, textStatus, jqXHR) {
        cmpButtonsUtils.buttons.statusTrue.call(
          this,
          data,
          element,
          response,
          textStatus,
          jqXHR
        );
      },
      statusFalse: function(data, element, response, textStatus, jqXHR) {
        cmpButtonsUtils.buttons.statusFalse.call(
          this,
          data,
          element,
          response,
          textStatus,
          jqXHR
        );
      },
    },
    requestM: {
      click: function(data, element) {
        cmpButtonsUtils.collectSelectedValues.call(this, data, element);
      },
      beforeSend: function(data) {
        if (this.options.value && this.options.value.constructor === Array) {
          return true;
        }
        if (data.uncheckedMessage) {
          cmpTrayAlert.show(data.uncheckedMessage, null, 5);
        }
        return false;
      },
      statusTrue: function(data, element, response, textStatus, jqXHR) {
        cmpButtonsUtils.buttons.statusTrue.call(
          this,
          data,
          element,
          response,
          textStatus,
          jqXHR
        );
      },
      statusFalse: function(data, element, response, textStatus, jqXHR) {
        cmpButtonsUtils.buttons.statusFalse.call(
          this,
          data,
          element,
          response,
          textStatus,
          jqXHR
        );
      },
    },
    requestMC: {
      click: function(data, element) {
        cmpButtonsUtils.collectSelectedValues.call(this, data, element);
      },
      beforeSend: function(data, element) {
        if (this.options.value && this.options.value.constructor === Array) {
          return cmpButtonsUtils.requestConfirm(element);
        }
        if (data.uncheckedMessage) {
          cmpTrayAlert.show(data.uncheckedMessage, null, 5);
        }
        return false;
      },
      statusTrue: function(data, element, response, textStatus, jqXHR) {
        cmpButtonsUtils.buttons.statusTrue.call(
          this,
          data,
          element,
          response,
          textStatus,
          jqXHR
        );
      },
      statusFalse: function(data, element, response, textStatus, jqXHR) {
        cmpButtonsUtils.buttons.statusFalse.call(
          this,
          data,
          element,
          response,
          textStatus,
          jqXHR
        );
      },
    },
    select: function(data, element) {
      $(element).toggleClass('selected');
      var $selectAll = $('[data-button][data-value="' + data.button + '"]'),
        $selectBox = $('[data-select-toggle-box="' + data.button + '"]'),
        $selected = $('[data-button="' + data.button + '"].selected');
      if ($selectAll.length) {
        if ($selected.length) {
          $selectAll
            .filter('.select')
            .removeClass('active')
            .addClass('disabled');
          $selectAll
            .filter('.deselect')
            .removeClass('disabled')
            .addClass('active');
        } else {
          $selectAll
            .filter('.deselect')
            .removeClass('active')
            .addClass('disabled');
          $selectAll
            .filter('.select')
            .removeClass('disabled')
            .addClass('active');
        }
      }
      if ($selectBox.length) {
        if ($selected.length) {
          $selectBox.removeClass('disabled').addClass('active');
        } else {
          $selectBox.removeClass('active').addClass('disabled');
        }
      }
    },
    selectAll: function(data, element) {
      if (data && data.value) {
        var $selected = $('[data-button="' + data.value + '"]:not([data-id])'),
          $selectBox = $('[data-select-toggle-box="' + data.value + '"]');
        if ($selected.length) {
          if (this.toggleButtons) {
            this.toggleButtons(element, data);
          }
          if ($(element).is('.select')) {
            $selected.addClass('selected');
          } else {
            $selected.removeClass('selected');
          }
        }
        if ($selectBox.length) {
          var $selected = $('[data-button="' + data.value + '"].selected');
          if ($selected.length) {
            $selectBox.removeClass('disabled').addClass('active');
          } else {
            $selectBox.removeClass('active').addClass('disabled');
          }
        }
      }
    },
    toggleBox: {
      click: function(data, element, fnName) {
        var $element = $(element);
        if ('multi' in data) {
          $element = $(
            '[data-button][data-id="' +
              (fnName ? fnName : 'toggleBox') +
              '"][data-value=' +
              data.value +
              ']'
          );
        }
        if ('value' in data) {
          var $target = $('[data-button-target="' + data.value + '"]');
          if (data.toggle && data.page !== 'contact') {
            if (
              !$element.hasClass('active') &&
              data.value === 'pressReleaseBox'
            ) {
              $element.addClass('active');
            } else {
              $element.removeClass('active');
            }
            // $element.removeClass('active');
            $element.data('toggle', false);
            $target.removeClass('enabled done'); // close sider on click btn "close"
            $element.data('toggle', false);
            let delayClose = 'closeDelay' in data ? data.closeDelay : 0;
            if (!isNaN(delayClose)) {
              let t;
              clearTimeout(t);
              t = setTimeout(function() {
                if ($target.hasClass('disabled')) {
                  $target.removeClass('disabled').addClass('enabled');
                } else {
                  $target.addClass('disabled');
                }
              }, delayClose * 1000);
            }
            if('hideScroll' in data){
              disableScroll('destroy');
            }
            return false;
          } else if (data.toggle && data.page == 'contact') {
            // $element.removeClass('active');
            // $element.data('toggle', false);
            // $target.removeClass('enabled done'); // close sider on click btn "close"
            $element.toggleClass('active');
            //$element.data('toggle', false);
            $target.toggleClass('enabled done');
            let delayClose = 'closeDelay' in data ? data.closeDelay : 0;
            if (!isNaN(delayClose)) {
              let t;
              clearTimeout(t);
              t = setTimeout(function() {
                //$target.addClass('disabled');
                $target.toggleClass('disabled');
              }, delayClose * 1000);
            }
            if('hideScroll' in data){
              disableScroll('destroy');
            }
            return false;
          } else {
            if (data.clear !== undefined && typeof data.clear == 'string') {
              $('[data-button][data-id][data-value="' + data.clear + '"].active:not([data-static])')
                .removeClass('active')
                .data('toggle', false);
              $('[data-button-target="' + data.clear + '"]:not([data-static])')
                .removeClass('enabled done')
                .addClass('disabled');
            } else if (data.clear == 1) {
              $('[data-button][data-id].active:not([data-static])')
                .removeClass('active')
                .data('toggle', false);
              $('[data-button-target]:not([data-static])')
                .removeClass('enabled done')
                .addClass('disabled');
            } else if (data.clear == 2) {
              $('[data-button][data-id].active:not([data-static])')
                .removeClass('active')
                .data('toggle', false);
              $('[data-button-target]:not([data-static])')
                .removeClass('enabled done')
                .addClass('disabled');
                setTimeout(() => {
                    let $overlay = $('.m1-drawer-overlay').is(':visible') ? $('.m1-drawer-overlay:visible') : $(document.body);
                    $overlay.on('click.clear-togle-box-' + data.value, function(event) {
                      let delayClose = 'closeDelay' in data ? data.closeDelay : 0;
                      if (!isNaN(delayClose)) {
                        let t;
                        clearTimeout(t);
                        t = setTimeout(function() {
                          $('[data-button-target="' + data.value + '"]:not([data-static])').addClass('disabled');
                        }, delayClose * 1000);
                      }
                      $('[data-button][data-id][data-value="' + data.value + '"].active:not([data-static])')
                      .removeClass('active')
                      .data('toggle', false);
                      $('[data-button-target="' + data.value + '"]:not([data-static])').removeClass('enabled done');
                      disableScroll('destroy');
                      $(this).off('click.clear-togle-box-' + data.value);

                      if (fnName) {
                        const fn = cmpButtons.config.buttons[`${fnName}Close`];
                        typeof fn === 'function' && fn(data, element);
                      }

                    });
                }, 0);
            } else if (data.clear == 3) {
              $(document.body).on(
                'click.clear-togle-box-' + data.value,
                function(event) {
                  $(
                    '[data-button][data-id][data-value="' +
                      data.value +
                      '"].active:not([data-static])'
                  )
                    .removeClass('active')
                    .data('toggle', false);
                  $(
                    '[data-button-target="' +
                      data.value +
                      '"]:not([data-static])'
                  )
                    .removeClass('enabled done')
                    .addClass('disabled');
                  $(this).off('click.clear-togle-box-' + data.value);

                  if (fnName) {
                    const fn = cmpButtons.config.buttons[`${fnName}Close`];
                    typeof fn === 'function' && fn(data, element);
                  }
                }
              );
            }

            $element.data('toggle', true);
            if('hideScroll' in data) {
              disableScroll({selector: `.${data.hideScroll}`});
            }
           
            if ($target.hasClass('enabled')) {
              $target.removeClass('enabled').addClass('disabled');
            } else {
              $target.removeClass('disabled').addClass('enabled');
            }

            // $target.removeClass('disabled').addClass('enabled');
            if ('delay' in data) {

              var delay = parseFloat(data.delay),
                t = false;
              if (!isNaN(delay)) {
                clearTimeout(t);
                t = setTimeout(function() {
                  if ($target.is('.enabled')) {
                    $target.addClass('done');
                    $element.addClass('active');
                  }
                }, delay * 1000);
              }
            } else {
               if ($element.hasClass('active')) {
                $element.removeClass('active');
                } else {
                  $element.addClass('active');
                }
            }
            return true;
          }
        }
      },
      blur: function(data, element) {
        if ('blur' in data && 'value' in data) {
          var $element = $(element);
          setTimeout(function() {
            $element.removeClass('active');
            $element.data('toggle', false);
            $('[data-button-target="' + data.value + '"]')
              .removeClass('enabled done')
              .addClass('disabled');
          }, 300);
        }
      },
    },
  },
};
