import { currentPage } from '../common/layout';
import { banners, commentsCountUpdate, loadCommentsNumber, lloadDisqus, bigImgLazyload, hitPageView, initCoralComments, coralCommentsEdition, initCoralByCommentsBtnEditions, getCookie, checkTcfApi } from '../common/utils/fn';
import { getStroeerGalleryBanners } from '../common/utils/adv-stroeer-mobile-gallery-loader';
import { getStickyVideo } from '../common/utils/sticky-video-loader';
import { getInfinteScroll } from '../common/utils/load-more-loader';
import 'jquery-mousewheel';
import '../common/lightgallery/jquery-lightgallery';
import '../common/lightgallery/jquery-lightgallery-aside';
import '../common/lightgallery/jquery-lightgallery-additional';
import '../common/lightgallery/jquery-lightgallery-topside';
import '../common/lightgallery/jquery-lightgallery-custom-content';
import '../common/msnt/msnt-lightslider.js';
import '../common/lightzoom';
import 'jquery-scrolltie';
import { cmpBubbles } from '../common/cmp-bubbles';
import { msntPhotoThumbGallery } from '../common/msnt/msnt-loader';
import { pointsOfInterest } from '../common/widgets/widget-points-of-interest';
import { compareImages } from '../common/widgets/widget-compare-images';
import { faqAccordeon } from '../common/widgets/widget-faq';
import { getNewsletterWidget } from '../common/widgets/widget-newsletter-loader';
import { loadDisqusComments } from '../common/jquery-lazy-load-3rd-party';
import shareTrend from '../common/share-trend';
import '../common/jquery.sticky';
import { getM1FindVehicleEu, getM1FindVehicleUs, getM1FindVehicleByType } from '../common/shop/m1-find-vehicle-widget-loader';
import { topComments } from '../common/top-comments';
// import m1FindVehicleByType from '../common/shop/m1-find-vehicle-by-type';
// import '../common/jquery-lazy-load-3rd-party';
// import '../common/modernizr';
// import '../common/player';
// import '../common/lazyload.picture';
// import '../common/lot-linx-car';
// import { renderEbay } from '../common/widgets/widget-ebay';
import { throttle, debounce } from 'throttle-debounce';
import { oneTrust } from '../common/onetrust';

currentPage.pageInit = ($page, pageData, pageId) => {
  var $window = $(window),
    $body = $(document.body),
    $page = $('#' + pageId),
    $recommendedBox = $page.find('.recommendedBox'),
    $dataEdtn = $('body').data('edtn'),
    $pageIsRtl = $('body').data('rtl');
    var infiniteScrollEdtn = ['it', 'ev_it', 'of', 'ot', 'id', 'ev_fr', 'ev_tr', 'tr', 'hu', 'me'],
    isVideoArticle = $('.videoBoxArticle').length ? true : false;
    // let cpUserIsLogged = $body.hasClass('cpauthenticated');
    // cpass
    // const $contentpassEditions = ['fr', 'ar', 'us', 'it', 'me', 'es', 'uk', 'tr', 'id'];
    // var cpAuthhint = getCookie('_cpauthhint');
    // let isDevEnv = document.body.classList.contains('development');

  $(
    '#article_box .sidebar, #container_3rd_party_service .sidebar'
  ).stickySidebar({
    containerSelector: '.content-area',
    limitedScroll: true
  });
  // cpass
  bigImgLazyload();
  if ($dataEdtn === 'me') {
    //hybridLazyLoad();
  }
// cpass
// function loadCodeAfterConsent() {
//   __tcfapi("addEventListener", 2, function (tcData, success) {
    
//     if (!tcData || !success) {
//       return;
//     }
  
//     console.log("CMP: __tcfapi event listener success");
  
//     if (
//       tcData.eventStatus !== "useractioncomplete" &&
//       tcData.eventStatus !== "tcloaded"
//     ) {
//       return;
//     }
  
//     console.log(
//       "CMP: __tcfapi event listener consent ready",
//       tcData.vendor.consents
//     );
  
//     if (!tcData.vendor || !tcData.vendor.consents) {
//       return;
//     }

//     let consentWasGiven = Object.keys(tcData.vendor.consents).length > 0;

//     if (consentWasGiven) {
//       topComments.init({
//         config: { active: 1 },
//       });

//       if (!coralCommentsEdition.includes($dataEdtn)) loadCommentsNumber();

//       if (!isDevEnv) {
//         bigImgLazyload();
//       }

//       if ($dataEdtn == 'ar') {
//         commentsCountUpdate();
//         loadDisqusComments();
//       }
//     }

//     if (tcData.listenerId) {
//       __tcfapi("removeEventListener", 2, function () {}, tcData.listenerId);
//     }
//   });
// }

  // if ($contentpassEditions.includes($dataEdtn) && !cpAuthhint) {
  //   checkTcfApi().then(loadCodeAfterConsent).catch(error => {
  //     console.error("loadCodeAfterConsent error:", error);
  // });
  // }
  
  // if (!$contentpassEditions.includes($dataEdtn)) {
  //   topComments.init({
  //     config: { active: 1 },
  //   });
  // }

  topComments.init({
    config: { active: 1 },
  });

  // $('.embed-item').each(function(index, element) {
  //   var $el = $(element),
  //     code = $el.data('embedCode');
  //   if (code) {
  //     $el.html(decodeURIComponent(code));
  //   }
  // });
  oneTrust.initEmbeds($('.content-area-box'));
  // cpass
  if ($dataEdtn == 'de' || $dataEdtn == 'ev_de') {
    oneTrust.initEmbedVideo($('.root'));
  }

  msntPhotoThumbGallery().then((api) => {
    api.msntPhotoThumbGallery();
  });
// slideshow functionality
  var slideShowBannerTimeout = null;
  var $slide_show_sidebar_banner = $('#slide_show_sidebar_banner');
  function reloadSlideShowBanners() {
    if($slide_show_sidebar_banner.length){
      clearTimeout(slideShowBannerTimeout);
      slideShowBannerTimeout = setTimeout(function() {
        $slide_show_sidebar_banner.find('.ap').removeAttr('data-ready').empty();
        banners($slide_show_sidebar_banner);
      }, 1000);
    }
  }
  var $articleSlideshowPhotosList = $('.m1-article-slideshow-photos-list'),
    $articleSlideshowPhotosListInPhotos = $articleSlideshowPhotosList.find(
      '.m1-article-slideshow-photos-related-content'
    );

  function gtmSlideshowHit(id) {
    if ('gtmDataLayer' in window && id) {
      gtmDataLayer.push({
        event: 'popupPhotoGallaryEntityChanged',
        entityType: 'photo',
        entityId: id,
      });
    }
  }
  if ($articleSlideshowPhotosList.length) {
    const $slideshow = $('.m1-article-slideshow');

    var $items = $articleSlideshowPhotosList.find('.item');
    var $relatedWidget = $(
      '.postContent .postBody section[data-widget="related-content"]'
    ).first();
    if ($relatedWidget.length) {
      $items.each(function(i, v) {
        var $item = $(v);
        if ($item.find('section[data-widget="related-content"]').length === 0) {
          $relatedWidget.clone(true, true).appendTo($item);
          bigImgLazyload();
        }
      });
    }

    // $slideshow.length ? $slideshow.trigger('focus') : null;
    const slideshow = document.querySelector('.m1-article-slideshow');

    if (slideshow) {
        slideshow.focus({
          preventScroll: true,
        });
    }

    $slideshow.on(
      'click keyup',
      function(event) {
        var $slideShowOn = $('.m1-article-slideshow-on, .m1-article-slideshow-on');
        var slideShowOn = $slideShowOn.length;
        var pageUrl = $slideshow.data('page-url');
        const ArrowNext = $pageIsRtl ? 'ArrowLeft' : 'ArrowRight';
        const ArrowPrev = $pageIsRtl ? 'ArrowRight' : 'ArrowLeft';

        const prevEventers = event.target.classList.contains('m1-article-slideshow-prev') ||
                      event.target.classList.contains('m1-article-slideshow-button-prev') ||
                      event.target.classList.contains('m1-article-slideshow-button-bottom-prev') ||
                      event.key === ArrowPrev;
        const nextEventers = event.target.classList.contains('m1-article-slideshow-next') ||
                      event.target.classList.contains('m1-article-slideshow-button-next') ||
                      event.target.classList.contains('m1-article-slideshow-button-bottom-next') ||
                      event.key === ArrowNext;
        if (slideShowOn) {
          var $slideShowList = $slideShowOn
            .find('.m1-article-slideshow-photos-list, .m1-article-slideshow-photos-list ')
          
          if ($slideShowList.length) {
            var $currentSlide = $slideShowList.find('.item.current');

            if ($currentSlide.length) {
              var $next = [];

              if (prevEventers) {
                $next = $currentSlide.prev();
              } else if (nextEventers) {
                $next = $currentSlide.next();
              } else {
                return;
              }

              if ($next.length) {
                var id = $next.data('id');

                if (id && pageUrl) {
                  var adv = $currentSlide.data('adv');
                  if (adv !== undefined) {
                    var $currentSlideAd = $currentSlide.find('.adv-box');

                    if (adv) {
                      if (!$currentSlideAd.length) {
                        $currentSlideAd = $(
                          '<div class="adv-box"></div>'
                        ).appendTo(
                          $currentSlide.find('.m1-article-slideshow-photo')
                        );
                        var $slide_show_sidebar_banner = $(
                          '#slide_show_sidebar_banner'
                        );
                        $slide_show_sidebar_banner
                          .find('.ap')
                          .clone()
                          .removeAttr('data-ready')
                          .empty()
                          .appendTo($currentSlideAd);
                      }
                      $currentSlide.addClass('show-ad');
                      $currentSlideAd
                        .find('.ap')
                        .empty()
                        .removeAttr('data-ready');
                      banners($currentSlideAd);
                      $currentSlide.data('adv', false);
                      return;
                    } else {
                      $currentSlide.data('adv', true);
                    }
                    $currentSlide.removeClass('show-ad');
                  }
                  //banner insert
                  $currentSlide.removeClass('current');
                  $next.addClass('current');
                  history.replaceState({}, null, pageUrl + id + '/');
                  gtmSlideshowHit(id);
                  hitPageView(pageUrl + id + '/');
                  reloadSlideShowBanners();
                }
              } else {
                $slideShowOn
                  .removeClass('m1-article-slideshow-on')
                  .addClass('m1-article-slideshow-off');
                if (pageUrl) {
                  history.replaceState({}, null, pageUrl);
                  hitPageView(pageUrl);
                }
                $currentSlide.removeClass('current');
                $slideShowList.each(function(){
                  $(this).find('.item')
                  .first()
                  .addClass('current');
                })

                reloadSlideShowBanners();
              }
            }
          }
        } else {
          var $slideShow = $('.m1-article-slideshow-off');
          if ($slideShow.length && nextEventers) {
            var $slideShowList = $slideShow
              .find('.m1-article-slideshow-photos-list')
              .first();
            if ($slideShowList.length) {
              var $currentSlide = $slideShowList.find('.item.current');
              if ($currentSlide.length) {
                var id = $currentSlide.data('id');
                if (id && pageUrl) {
                  history.replaceState({}, null, pageUrl + id + '/');
                  gtmSlideshowHit(id);
                  hitPageView(pageUrl + id + '/');
                  $slideShow
                    .addClass('m1-article-slideshow-on')
                    .removeClass('m1-article-slideshow-off');
                  reloadSlideShowBanners();
                }
              }
            }
          }
        }
      }
    );

    $slideshow.on('slide.next', event => {
      const $slide = $slideshow.find('.current');
      $slide.find('.m1-article-slideshow-next').trigger('click');
    });

    $slideshow.on('slide.prev', event => {
      const $slide = $slideshow.find('.current');
      $slide.find('.m1-article-slideshow-prev').trigger('click');
    });
    /**
     * Swipe
     */
    class Point {
      constructor(x, y) {
        this._x = x || 0;
        this._y = y || 0;
      }

      get x() {
        return this._x;
      }

      set x(x) {
        this._x = x;
      }

      get y() {
        return this._y;
      }

      set y(y) {
        this._y = y;
      }
    }

    const distanceMinX = 50;
    const distanceMaxY = 250;
    const timeSwipeMax = 250;

    let isSwipe = false;
    let direction = 0;
    let timeStart = 0;
    let distance = new Point();
    let start = new Point();
    let end = new Point();

    $slideshow.on('touchstart', '.m1-article-slideshow-photo', function(event) {
      let touch = event.changedTouches[0];

      start.x = touch.clientX;
      start.y = touch.clientY;

      direction = 0;
      timeStart = new Date().getTime();
    });

    $slideshow.on('touchmove', '.m1-article-slideshow-photo', function(event) {
      let touch = event.changedTouches[0];
      let isScroll =
        Math.abs(touch.clientX - start.x) < Math.abs(touch.clientY - start.y);

      if (!isScroll) {
        event.preventDefault();
      }
    });

    $slideshow.on('touchend', '.m1-article-slideshow-photo', function(event) {
      let touch = event.changedTouches[0];

      end.x = touch.clientX;
      end.y = touch.clientY;

      distance.x = end.x - start.x;
      distance.y = end.y - start.y;

      direction = distance.x > 0 ? -1 : 1;

      isSwipe =
        new Date().getTime() - timeStart <= timeSwipeMax &&
        Math.abs(distance.x) >= distanceMinX;

      if (!isSwipe) {
        return;
      }

      if (Math.abs(distance.y) >= distanceMaxY) {
        return;
      }

      if (direction === 1) {
        $slideshow.trigger('slide.next');
      } else if (direction === -1) {
        if (
          $(this)
            .parent()
            .is('.m1-article-slideshow-main-photo-box')
        ) {
          return;
        }
        $slideshow.trigger('slide.prev');
      }
      direction = 0;
    });

    /**
     * Slide width animation
     */
    /*
    const distanceMinX = 70;
    const moveThrottle = throttle(1000 / 60, moveHandle);

    let direction = 0;
    let distance = new Point();
    let start = new Point();
    let animationID;
    let stop = false;

    function moveHandle(event) {
      if (stop) return;

      const touch = event.changedTouches[0];

      let X;

      distance.x = Math.abs(Math.abs(start.x) - Math.abs(touch.pageX));
      distance.y = Math.abs(Math.abs(start.y) - Math.abs(touch.pageY));

      X = start.x - touch.pageX;

      direction = X > 0 ? -1 : 1;

      const $slide = $slideshow.find('.current');
      let $nextSlide;

      if (distance.x > distance.y + 10) {
        if (direction === 1) {
          $nextSlide = $slide.prev();

          if (!$nextSlide.length) {
            $nextSlide = $slideshow.find('.item').last();
          }

          $nextSlide.addClass('preShow');
        } else if (direction === -1) {
          $nextSlide = $slide.next();

          if (!$nextSlide.length) {
            $nextSlide = $slideshow.find('.item').first();
          }

          $nextSlide.addClass('preShow');
        }

        $slide.addClass('preHide');

        animationID = requestAnimationFrame(() => {
          $slide.css({
            transform: `translate3d(${-X}px, 0, 0)`,
            'box-shadow': `0 0 ${Math.abs(X) / 5}px rgba(0, 0, 0, 0.15)`,
          });

          $nextSlide.css({
            opacity: Math.abs(X) / 300,
          });
        });
      }
    }

    function reset(slide) {
      const $slide = slide || $slideshow.find('.current');
      let animationReset;

      cancelAnimationFrame(animationID);
      let $items = $slideshow.find('.item');
      animationReset = requestAnimationFrame(() => {
        $items.removeClass('preHide').removeClass('preShow');
        $items.css({
          opacity: 1,
          transform: `translate3d(0, 0, 0)`,
          'box-shadow': 'none',
        });
        cancelAnimationFrame(animationReset);
      });
    }

    $slideshow.on('touchstart', '.m1-article-slideshow-photo', event => {
      stop = false;
      let touch = event.changedTouches[0];

      start.x = touch.pageX;
      start.y = touch.pageY;

      direction = 0;
    });

    $slideshow.on('touchend', '.m1-article-slideshow-photo', event => {
      stop = true;
      const touch = event.changedTouches[0];
      const $slide = $slideshow.find('.current');

      let X = start.x - touch.pageX;
      direction = X > 0 ? -1 : 1;

      if (distanceMinX < Math.abs(start.x - touch.pageX)) {
        $slide.css({
          transform: `translate3d(${direction * 150}%, 0, 0)`,
        });

        if (direction === 1) {
          $slideshow.trigger('slide.prev');
        } else if (direction === -1) {
          $slideshow.trigger('slide.next');
        }
      }
      reset();
    });

    $slideshow.on('touchmove', '.m1-article-slideshow-photo', event => {
      moveThrottle(event);
    });
    */
  }
  // slideshow functionality

  function adjustLiveTextHeight() {
    var $postBox = $('#post_box'),
      $postTopInfo = $postBox.find('.postTopInfo'),
      layout_header_height = $('#layout_header').height() || 0,
      $post_sidebar = $('#post_sidebar'),
      is_stick_share = 0,
      is_hide_aside = 0;

    var $liveTextIframe = $postBox.find('iframe[src*="/live-text/embed/"]');
    $window.on('message', function(event) {
      event = event.originalEvent;

      var origin = event.origin || event.originalEvent.origin;

      if (
        !/ntlbss\.com|motorsport\.com/.test(origin) ||
        (event.data &&
          event.data.constructor !== Object &&
          event.data.type !== 'live-text-resize')
      )
        return;

      $liveTextIframe.height(event.data.height);
    });
}

adjustLiveTextHeight();

  // var $sharesTrend = $('#shares_speed_graph'),
  // 	shareLineData = fn.getSharesGraphPoints($sharesTrend, $sharesTrend.data('shares')),
  // 	d3LineFn;

  // if (shareLineData.length) {
  //       d3LineFn = d3.svg.line()
  //           .x(function(d) { return d.x; })
  //           .y(function(d) { return $sharesTrend.height() - d.y; })
  //           .interpolate("monotone");

  //       $('#shares_line').attr('d', d3LineFn(shareLineData));
  //   }

  shareTrend();

  // easy way to disable full width articles without build
  function handleHideAsideScroll() {
    if ($body.hasClass('fullwidthArticles')) {
      
      let $postBox = $('#post_box'),
      $post_sidebar = $('#post_sidebar'),
      is_hide_aside = 0;

      $window.on('scroll.hide_aside', function() {
        if (
          document.documentElement.clientWidth >= 1024 &&
          $post_sidebar.length
        ) {
          var postSidebarRect = $post_sidebar[0].getBoundingClientRect();

          if (postSidebarRect.bottom < 0) {
            // + document.documentElement.clientHeight
            if (is_hide_aside == 0) {
              $postBox.addClass('hide-aside');
            }
            is_hide_aside = 1;
          } else {
            if (is_hide_aside == 0) {
              $postBox.removeClass('hide-aside');
            }
            is_hide_aside = 0;
          }
        }
      });
    }
  }
  handleHideAsideScroll();

  function setupCompetitorsSlider() {
    var $competitors = [];
  
    function initCompetitorsSlider() {
      $competitors = $('#competitors');
      if ($competitors.length) {
        $competitors.lightSlider({
          loop: true,
          addClass: 'imageInlineGallery',
          item: 3,
          slideMargin: 25,
        });
      }
    }
  
    function destroyCompetitorsSlider() {
      if ($competitors.length) {
        $competitors.destroy();
        $competitors = [];
      }
    }
  
    function handleCompetitorsSliderResize() {
      var clientWidth = document.documentElement.clientWidth;
      if (clientWidth < 768) {
        destroyCompetitorsSlider();
      } else {
        initCompetitorsSlider();
      }
    }
  
    var clientWidth = document.documentElement.clientWidth;
    if (clientWidth >= 768) {
      initCompetitorsSlider();
    }
  
    $window.on('resize', handleCompetitorsSliderResize);
  }
  
  setupCompetitorsSlider();
  

  // var $articlePoster = $('.scrollTieBox');

  // $articlePoster.scrollTie({
  //   property: 'translateY',
  //   delay: function(el) {
  //     return 600;
  //   },
  //   stopAtValue: document.documentElement.clientHeight,
  //   speed: isTouch ? 0.15 : 0.3,
  //   //manualInit: true
  // });

  // let showDisqusButton = document.getElementById('show-disqus');
  let lloadDisqusEditions = ['ev_us', 'it', 'de', 'fr', 'id', 'ru', 'ev_fr', 'ev_it', 'ev_ru', 'om', 'ra_us', 'ev_br', 'br', 'ra_us'];
  //smooth anchor scroll
  let isClicked = false;
  $('.disqus-comment-count, .show-coral').click(function(event) {
    isClicked = true;
    var showDisqusButton = $('.show-disqus');
    var ifVisible = showDisqusButton.data('visible');
    let disqusWrapper = document.querySelector('.disqus-wrapper');
    let disqusFrameAlreadyExist = false;
    if (disqusWrapper) {
      let disqusWrapperIframe = disqusWrapper.querySelector('iframe');
      if (disqusWrapperIframe && disqusWrapperIframe.id.includes('disqus_comments')) {
        disqusFrameAlreadyExist = true;
      }
    }
    
    
    event.preventDefault();
    event.stopPropagation();

    var anchor;
    var commentsElement = $('#comments');
    
    if (commentsElement.length) {
      anchor = '#' + commentsElement.attr('id');
    } else {
      var string = $(this).attr('href').split('/');
      anchor = string[string.length - 1];
    }

    var distanceToAnchor = $(anchor).offset().top;
    var distanceToAnchorNew;
    var callTimes = 0;
    let callTimesMax = $dataEdtn === 'ra_us' ? 2 : 6;
    let additionalOffset = coralCommentsEdition.includes($dataEdtn) ? -100 : 500;

    $('html, body').animate({
      scrollTop: $(anchor).offset().top + additionalOffset
    }, 500);

    const resizeObserver = new ResizeObserver((entries, observer) => {
      for(let entry of entries){
          if (callTimes < callTimesMax) {
            calcResizeTimes();
          } else {
            resizeObserver.unobserve(document.querySelector('#post_box'));
            resizeObserver.unobserve(document.querySelector('#post_sidebar'));
            resizeObserver.unobserve(document.querySelector('#container_3rd_party_service'));
          }
      }
    })

    resizeObserver.observe(document.querySelector('#post_box'));
    resizeObserver.unobserve(document.querySelector('#post_sidebar'));
    resizeObserver.observe(document.querySelector('#container_3rd_party_service'));

    function calcResizeTimes() {
      distanceToAnchorNew = $(anchor).offset().top;

      if(distanceToAnchor != distanceToAnchorNew) {
        $('html, body').animate({
          scrollTop: distanceToAnchorNew + additionalOffset
        }, 500);
      }
      callTimes++;
    }

    if (lloadDisqusEditions.includes($dataEdtn) && ifVisible && !disqusFrameAlreadyExist) {
      if (!coralCommentsEdition.includes($dataEdtn)) {
        lloadDisqus();
        showDisqusButton.data('visible', false);
        showDisqusButton.hide();
      } 
    }
  });

  function scrollingToComments() {
    if (isClicked) return;
    const targetElement = document.querySelector('#show-disqus');
    const distanceToAnchor = targetElement ? targetElement.offsetTop : 0;

    if (window.location.hash === '#m1-top-comments__wrapper' && targetElement) {
      let callTimes = 0;
      let callTimesMax = $dataEdtn === 'ra_us' ? 2 : 6;
      const resizeObserver = new ResizeObserver((entries, observer) => {
        for(let entry of entries){
            if (callTimes < callTimesMax) {
              calcResizeTimes();
            } else {
              resizeObserver.unobserve(document.querySelector('#post_box'));
              resizeObserver.unobserve(document.querySelector('#post_sidebar'));
              resizeObserver.unobserve(document.querySelector('#container_3rd_party_service'));
            }
        }
      })
  
      resizeObserver.observe(document.querySelector('#post_box'));
      resizeObserver.unobserve(document.querySelector('#post_sidebar'));
      resizeObserver.observe(document.querySelector('#container_3rd_party_service'));
      const distanceToAnchorNew = targetElement.offsetTop;

      function calcResizeTimes() {
        if(distanceToAnchor != distanceToAnchorNew) {
          $('html, body').animate({
            scrollTop: distanceToAnchorNew + 300
          }, 500);
        }
        callTimes++;
      }
    }
  }

  window.addEventListener('message', function(e){
    let data = e.data;
    if (data === 'newComment') {
      gtmDataLayer.push({
        event: data,
      });
    }
  })

  scrollingToComments();
  pointsOfInterest();
  compareImages();
  faqAccordeon();
  // if (adplayer && typeof adplayer.init === 'function') {
  // 	adplayer.init();
  // }

  cmpBubbles.init({
    minWidth: 640,
    error: function(data, element, response, textStatus, jqXHR) {
      cmpTrayAlert.show(
        jqXHR.responseJSON &&
        jqXHR.responseJSON.constructor === Object &&
        jqXHR.responseJSON.message
          ? jqXHR.responseJSON.message
          : 'Bad response (JSON)',
        'error',
        30
      );
    },
  });

  function adjustFacebookVideoWrapper() {
    let facebookWrapper = document.getElementsByClassName('facebook-video-wrapper')[0];
  
    if (facebookWrapper) {
      let fbSizer = facebookWrapper.querySelector('.sizer');
      let videoIframe = facebookWrapper.querySelector('iframe');
  
      let fbSizerHeight = fbSizer.offsetHeight;
      let videoIframeWidth = videoIframe.getAttribute('width');
      let videoIframeHeight = videoIframe.getAttribute('height');
      let iframeAspectRatio = videoIframeHeight / videoIframeWidth;
  
      if (iframeAspectRatio === 1) {
        videoIframe.height = fbSizerHeight;
        videoIframe.width = fbSizerHeight;
      } else if (iframeAspectRatio === 0.5625) {
        facebookWrapper.classList.add('facebook-iframe-sizer');
      } else if (iframeAspectRatio > 1) {
        videoIframe.height = fbSizerHeight;
        videoIframe.width = fbSizerHeight / iframeAspectRatio;
      } else {
        videoIframe.height = fbSizerHeight;
        facebookWrapper.classList.add('facebook-iframe-sizer');
      }
    }
  }
  
  adjustFacebookVideoWrapper();
  if (isVideoArticle) {
    getStickyVideo().then((api) => {
      api.stickyVideo($dataEdtn, infiniteScrollEdtn, $window);
    });
  }

  if ($dataEdtn == 'de' || $dataEdtn == 'ev_de' && !cpAuthhint) {
    getStroeerGalleryBanners().then((api) => {
      api.initDeMobileGalleryBanners();
    });
  }

  if ($dataEdtn == 'us' || $dataEdtn == 'ra_us' || $dataEdtn == 'ev_us') {
    const headerRequest = new Request(document.location);
    fetch(headerRequest).then((response) => {
      const headerTitle = "Geoip-Country";
      if(response.headers.has(headerTitle)){
        var title = response.headers.get(headerTitle);
        gtmDataLayer.push({
          event: 'geoIpIsPresent',
          'userGeoIp': title
        });
      }
    });
  }

  //let ebayWrapper = document.querySelector('.ebay-widget');

  // if ($dataEdtn == 'it' && ebayWrapper) {
  //   renderEbay();
  // }

  // function dataSrcReplacer() {
  //   console.log('helloworld');
  //   var vidDefer = document.getElementsByTagName('iframe');
  //   console.log(vidDefer);
  //   for (var i=0; i<vidDefer.length; i++) {
  //     if(vidDefer[i].getAttribute('data-src')) {
  //        vidDefer[i].setAttribute('src',vidDefer[i].getAttribute('data-src'));
  //     }
  //   }
  // }

  // window.onload = dataSrcReplacer;
  $('.author-js-link').on('click', function () {
    window.location.href = $(this).data('href');
  });

  function initializeLightzoom() {
    if ($('.lightzoom').length > 0) {
      $( '.lightzoom' ).lightzoom({
        speed:                 100,   // скорость появления
        imgPadding:            1,    // значение отступа у изображения
        overlayOpacity:        '0.5', // прозрачность фона (от 0 до 1)
        viewTitle:             false, // true, если надо показывать подпись к изобажению
        isOverlayClickClosing: true, // true, если надо закрывать окно при клике по затемненной области
        isWindowClickClosing:  true, // true, если надо закрывать окно при клике по любой области
        isEscClosing:          true, // true, если надо закрывать окно при нажатии на кнопку Esc
        boxClass:              '',    // позволяет задавать класс окну обертке (с версии 1.1.0)
        overlayColor:          '',    // позволяет задавать цвет фону (с версии 1.1.0)
        titleColor:            '',    // позволяет задавать цвет подписи (с версии 1.1.0)
      });
    }
  }

  initializeLightzoom();

  // DEV: Section Pages: html and css structure for Pagination
  // const setIframeModifier = function () {
  //   const embedItems = document.querySelectorAll('.embed-item');
  //   if (embedItems.length === 0) {
  //     return;
  //   }
  //   return [...embedItems].map((item) => {
  //     const itemEl = item.firstElementChild;
  //     if (itemEl.nodeName === 'IFRAME') {
  //       const itemSrc = itemEl.getAttribute('src');
  //       const itemHeight = itemEl.getAttribute('height');
  //       const itemWidth = itemEl.getAttribute('width');
  //       const iframeAspectRatio = itemHeight/itemWidth;
  //       if (iframeAspectRatio === 1 && itemSrc.includes('facebook.com/plugins')) {
  //         item.classList.add('embed-item_fb');
  //       }
  //     }
  //   });
  // };

  // window.addEventListener('load', setIframeModifier);

  // let embedItems = document.querySelectorAll('.embed-item');
  // console.log(embedItems);
  // if(embedItems.length) {
  //   const config = {
  //     attributes: true,
  //     childList: true,
  //     subtree: true
  //   };

  //   const callback = function(mutationsList, observer) {
  //     // console.log('called');
  //     for (let mutation of mutationsList) {
  //         if (mutation.type === 'childList' ||  mutation.type === 'subtree') {
  //             console.log(mutation.target);
  //             console.log('A child node has been added or removed.');
  //         }
  //     }
  //   };

  //   const observer = new MutationObserver(callback);

  //   for (let embedItem of embedItems) {

  //     if (embedItem.innerHTML !== '') {
  //       console.log(embedItem.innerHTML);
  //       return;
  //     }
  //     observer.observe(embedItem, config);
  //   }
  // }
  getM1FindVehicleEu().then((api) => {
    api.m1FindVehicleEu();
  });
  getM1FindVehicleUs().then((api) => {
    api.m1FindVehicleUs();
  });
  getM1FindVehicleByType().then((api) => {
    api.m1FindVehicleByType();
  });

  getNewsletterWidget().then((api) => {
    api.newsletter();
  });
  // if (infiniteScrollEdtn.includes($dataEdtn)) {
  //   const comentsCounter = document.querySelector('.disqus-comment-count');
  //   const commentsContainer = document.querySelector('.comments-container');
  //   comentsCounter.remove();
  //   commentsContainer.remove();
  // } else {
  //   commentsCountUpdate();
  //   loadDisqusComments();
  // }

  // if (!infiniteScrollEdtn.includes($dataEdtn) && !cpAuthhint) {
  //   commentsCountUpdate();
  //   loadDisqusComments();
  // }
  //cpass
  if (!infiniteScrollEdtn.includes($dataEdtn)) {
    commentsCountUpdate();
    loadDisqusComments();
  }

  // commentsCountUpdate();
  // loadDisqusComments();

  //bannerLazyload();
  if (!coralCommentsEdition.includes($dataEdtn)) loadCommentsNumber();
  const coralContainer = $('#coral_thread');
  const showCoralCommentsBtn = document.querySelector('#show-disqus');
  // const showCoralCommentsBtnTopComments = document.querySelector('.m1-top-comments__empty > span');
  const topCommentsEmptyBlock = document.querySelector('.m1-top-comments');

  if (coralCommentsEdition.includes($dataEdtn) && initCoralByCommentsBtnEditions.includes($dataEdtn) && coralContainer.length) {
    showCoralCommentsBtn.addEventListener('click', () => {
      initCoralComments();
      if (showCoralCommentsBtn && topCommentsEmptyBlock) {
        showCoralCommentsBtn.remove();
        topCommentsEmptyBlock.remove();
      }
    });
    // if (showCoralCommentsBtnTopComments) {
    //   showCoralCommentsBtnTopComments.addEventListener('click', () => {
    //     initCoralComments();
    //     showCoralCommentsBtn.remove();
    //     topCommentsEmptyBlock.remove();
    //   });
    // }
  } else if (coralCommentsEdition.includes($dataEdtn) && coralContainer.length) {
    initCoralComments();
  }
  $('.photo-title a').on('click', function(e) {
    e.stopPropagation();
  });
  
  function initializeAuthorSocials() {
    const authorSocialsItems = document.querySelectorAll('.author-socials-item');
  
    if (authorSocialsItems.length > 0) {
      authorSocialsItems.forEach((item) => {
        item.addEventListener('click', handleAuthorSocialsClick);
      });
    }
  }
  
  function handleAuthorSocialsClick(event) {
    const outlink = event.currentTarget.dataset.outlink;
    const decodedOutlink = new TextDecoder('utf-8').decode(Uint8Array.from(atob(outlink), c => c.charCodeAt(0)));
    window.open(decodedOutlink, '_blank');
  }
  
  initializeAuthorSocials();
  

  if($dataEdtn === 'de' || $dataEdtn === 'ev_de') {
    const trendingWrapper = document.querySelector('.trending-content');
    const outbrainWrapper = document.querySelector('.outbrain_service');
    if(!!trendingWrapper && !!outbrainWrapper && window.innerWidth <= 1023) {
      const parentElement = outbrainWrapper.parentNode;
      parentElement.insertAdjacentElement('afterend', trendingWrapper);
    }
  }

// Infinite sroll functionality article
var abCookie = getCookie('abtest');
if (infiniteScrollEdtn.includes($dataEdtn)) {
  if ($dataEdtn !== 'it' || ($dataEdtn == 'it' && (abCookie == 'Auto-v1.3' || abCookie == 'Auto-v1.4'))) {
    getInfinteScroll().then((api) => {
      api.loadMorePages(adjustFacebookVideoWrapper, shareTrend, handleHideAsideScroll, setupCompetitorsSlider, adjustLiveTextHeight, isVideoArticle, getStickyVideo, $dataEdtn, infiniteScrollEdtn, $window, initializeAuthorSocials, initializeLightzoom, bigImgLazyload, pointsOfInterest, compareImages, faqAccordeon, msntPhotoThumbGallery, getM1FindVehicleEu, getM1FindVehicleUs, getM1FindVehicleByType, cmpBubbles, oneTrust);
    });
  }  
}
// Infinite article sroll functionality
};
