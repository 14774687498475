import { initEmbeds, getCookie, setCookie, commentsCountUpdate, loadCommentsNumber } from './utils/fn';
import { loadDisqusComments } from '../common/jquery-lazy-load-3rd-party';
import { topComments } from '../common/top-comments';
import $ from 'jquery';
// import $ from 'jquery';
//import ShareBoxInline from '../../../components/shares-box/share-box-inline';



export const oneTrust =  {
    params: null,
    usedMethods: {
      'initEmbeds': new Map(),
      'initShareBlock': new Map(),
      'initEmbedVideo': new Map(),
      'initFeaturedVideo': new Map(),
      'initCommentsforCPusers': new Map(),
    },
    shareBlocks: [],
    buttonSelector: '#ot-sdk-btn.ot-sdk-show-settings',
    oneTrustCookieName: 'OptanonConsent',
    currentEdition: $('body').data('edtn'),
    excludeEditions: ['br', 'ev_br'],
    currentEnvironment: $('body').data('env'),
    isDevelopment: false,
    // sdas

    SOCIAL_MEDIA_COOKIE: 'C0005:1',
    FUNCTIONAL_COOKIE: 'C0003:1',

    _init: false,

    getParams() {
      const _this = window.msOneTrust;

      if (_this.params) {
        return _this.params;
      }

      _this.params = {};

      if (window.msAppOneTrustParams) {
        const params = window.msAppOneTrustParams;
        typeof params === 'string' ? _this.params = JSON.parse(params) : _this.params = params;
      } else {
        const cookie = getCookie(_this.oneTrustCookieName);

        // console.log(_this.oneTrustCookieName);
        // console.log('cookie ' + cookie);

        if (!cookie && !_this.isExcludedEdition()) {

          setTimeout(() => {
            _this.cookieChanged();
          }, 1000);
          return _this.params;
        }

        const params = new URLSearchParams(cookie);

        for(var [key, value] of params.entries()) {
          _this.params[key] = params.get(key);
        }

        if (!_this.params['groups'] && !_this.isExcludedEdition()) {
          setTimeout(() => {
            _this.cookieChanged();
          }, 1000);
          return _this.params;
        }
      }

      return _this.params;
    },

    init() {
      const _this = window.msOneTrust;
      // console.log('enter init');

      if (_this._init) {
        return;
      }

      _this._init = true;
      
      _this.oneTrustButton = $(window.msOneTrust.buttonSelector);

      if (_this.oneTrustButton.length) {
        $('body').on('click', '.ms-onetrust_stub-button', () => {
          _this.oneTrustButton.trigger('click');
        })
      }


      window.OneTrust && 
      window.OneTrust.OnConsentChanged && 
      typeof window.OneTrust.OnConsentChanged === 'function' && 
      window.OneTrust.OnConsentChanged(() => {
        window.msOneTrust.cookieChanged();
      });

      if (_this.isDevelopment) {
        _this._setCookie(1);
      }

      _this.cookieChanged();
    },

    isAlowed(code) {
      const _this = window.msOneTrust;
      const params = _this.getParams(); 
      return _this.isExcludedEdition() || (params && params['groups'] && params['groups'].search(code) != -1);
    },

    isExcludedEdition() {
      const _this = window.msOneTrust;
      return _this.excludeEditions.includes(_this.currentEdition);
    },

    initEmbeds($page) {
      const _this = window.msOneTrust;
      // console.log('embeds init');

      if (!_this.usedMethods['initEmbeds'].has($page)) {
        _this.usedMethods['initEmbeds'].set($page, $page);
      }

      if (oneTrust.currentEnvironment == 'development' || oneTrust.currentEnvironment == 'stage' || oneTrust.currentEnvironment == 'test') {
        if (_this._init) {
          return;
        }
      } else {
        if (!_this._init) {
          return;
        }
      }

      if (oneTrust.currentEnvironment === 'development' || oneTrust.currentEnvironment == 'test') {
        initEmbeds($page);
        return;
      }

      if (!_this.isAlowed(_this.SOCIAL_MEDIA_COOKIE)) {
        $page.find('.embed-item').each((index, element) => {
          $(element).empty().append(_this.getStub());
        });

        $page.find('.ms-share_twitter').hide();
        return;
      }
      
      $page.find('.ms-share_twitter').show();
      initEmbeds($page);
      setInterval(function() { 
        if (typeof instgrm === 'object') {
          instgrm.Embeds.process();
        }
      }, 2500);    
    },

    initCommentsforCPusers($page) {
      const _this = window.msOneTrust;
      const $body = $(document.body);
      const edtn = $body.data('edtn');
      let cpCookieExists = document.cookie.indexOf('_cpauthhint');
      const msntLoginScript = document.createElement('script');
      msntLoginScript.src = "https://accounts.motorsportnetwork.com/api/v1.0/script/81";
      console.log('embeds init 1');

      if (!_this.usedMethods['initCommentsforCPusers'].has($page)) {
        _this.usedMethods['initCommentsforCPusers'].set($page, $page);
      }

      if (oneTrust.currentEnvironment == 'development' || oneTrust.currentEnvironment == 'stage' || oneTrust.currentEnvironment == 'test') {
        if (_this._init) {
          return;
        }
      } else {
        if (!_this._init) {
          return;
        }
      }

      // cpCookieExists !== -1 && 

      console.log('embeds init 2');
      if (!_this.isAlowed(_this.FUNCTIONAL_COOKIE)) {
        console.log('embeds init 3');
        $page.find('#comments-stub').each((index, element) => {
          $(element).empty().append(_this.getStub());
        });
        return;
      } else {
        console.log('embeds init 4');
        let commentsCounters =  $page.find('.item .disqus-comment-count_wrapper');
        if (commentsCounters.length) {
          commentsCounters.each((index, element) => {
            $(element).css('display', 'inline-flex');
          });
          return;
        }

        $page.find('#comments-stub').each((index, element) => {
          $(element).remove();
        });
        $page.find('#comments .commentsNumber.show-disqus.show-coral').css('display', 'inline-flex');
        if (edtn != 'ar' && edtn != 'ev_ar') {
          document.head.appendChild(msntLoginScript);
          topComments.init({
            config: { active: 1 },
          });
        }

        if (edtn == 'ar' || edtn == 'ev_ar') {
          console.log('embeds init 5');
          loadCommentsNumber();
          commentsCountUpdate();
          loadDisqusComments();
        }

        $page.find('#comments .commentsNumber.show-disqus.show-coral').on('click', function (e) {
          console.log('click!!', this);
          const topCommentsBlockck = document.querySelector('.m1-top-comments');
          $(this).hide();
          $(topCommentsBlockck).hide();
        });
      }
    },
    
    initEmbedVideo($page) {
      const _this = window.msOneTrust;

      if (!_this.usedMethods['initEmbedVideo'].has($page)) {
        _this.usedMethods['initEmbedVideo'].set($page, $page);
      }

      if (oneTrust.currentEnvironment == 'development' || oneTrust.currentEnvironment == 'stage' || oneTrust.currentEnvironment == 'test') {
        if (_this._init) {
          return;
        }
      } else {
        if (!_this._init) {
          return;
        }
      }

      if (!_this.isAlowed(_this.FUNCTIONAL_COOKIE)) {
        setCookie('cpuserNoVideo', 1);
        $page.find('.video-player').each((index, element) => {
          if (!$(element).parent().hasClass('videoBoxArticle-wrapper')) {
            $(element).hide().parent().append(_this.getStub());
          }
        });
        return;
      } else {
        setCookie('cpuserNoVideo', 0);
        $page.find('.video-player').each((index, element) => {
          if ($(element).parent().hasClass('videoBoxArticle-wrapper')) {
            var $videoWrapper = $('.videoBoxArticle .video-player');
            const clearSvg = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
              <title>clear</title>
              <path d="M25.312 9.216l-7.424 7.456 7.424 7.424-1.888 1.888-7.424-7.456-7.424 7.456-1.888-1.888 7.424-7.424-7.424-7.456 1.888-1.856 7.424 7.424 7.424-7.424z"></path>
              </svg>`;
              if (!$videoWrapper.hasClass('other-videos-video-player')) {
                $videoWrapper.html('<div class="video-player-close icon-cross">' + clearSvg + '</div><iframe allowfullscreen frameborder="0" allow="autoplay" src="' + $videoWrapper.data('video-url') + '"></iframe>');
              }
          } else {
            $(".video-player").nextAll(".ms-onetrust_stub").remove();
            $(element).show();
          }
        });
      }
    },

    initShareBlock($page) {
      const _this = window.msOneTrust;

      if (!_this.usedMethods['initShareBlock'].has($page)) {
        _this.usedMethods['initShareBlock'].set($page, $page);
      }

      if (oneTrust.currentEnvironment == 'development' || oneTrust.currentEnvironment == 'stage' || oneTrust.currentEnvironment == 'test') {
        if (_this._init) {
          return;
        }
      } else {
        if (!_this._init) {
          return;
        }
      }

      if (oneTrust.currentEnvironment === 'development' || oneTrust.currentEnvironment == 'test')  {
        $page.find('.shareBox').show();
        return;
      }

      if (!_this.isAlowed(_this.SOCIAL_MEDIA_COOKIE)) {
        $page.find('.shareBox').hide();
        return;
      }

      $page.find('.shareBox').show();
    },

    cookieChanged() {
      const _this = window.msOneTrust;
      _this.params = null;

      for (const methodName in _this.usedMethods) {
        if(_this.usedMethods.hasOwnProperty(methodName) && _this.hasOwnProperty(methodName)) {
          for(let params of _this.usedMethods[methodName].values()) {
            _this[methodName](params);
          }
        }
      }

      return true;
    },

    getStub() {
      return `<div class="ms-onetrust_stub">
                <h3 class="ms-onetrust_stub-title">${i18n.cookie_settings}</h3>
                <p class="ms-onetrust_stub-text">${i18n.content_blocked}</p>
                <p class="ms-onetrust_stub-text">${i18n.click_to_show_content}</p>
                <div class="ms-onetrust_stub-button">${i18n.open_settings}</div>
              </div>`;
    },

    _setCookie(val) {
      // console.log('set cookie');
      const _this = window.msOneTrust;
      if (oneTrust.isDevelopment) {
        // console.log('_this.oneTrustCookieName' + ' ' +_this.oneTrustCookieName);
        const url = new URLSearchParams();
        url.append('groups', val ? _this.SOCIAL_MEDIA_COOKIE : 'C0005:0');
        // $.cookie(_this.oneTrustCookieName, url.toString());
        setCookie(_this.oneTrustCookieName, url.toString, 0);
      }
    },
 
    _switch (val) {
      const _this = window.msOneTrust;
      if (oneTrust.isDevelopment) {
        // _this.params = {};
        _this._setCookie(val);
        _this.cookieChanged();
      }
    }
}

window.msOneTrust = oneTrust;

((oneTrust.isDevelopment) || window.msOneTrust.isExcludedEdition()) &&  window.msOneTrust.init();


$(window).on('oneTrustReady', oneTrust.init)