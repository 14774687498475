import d3Line from "d3-shape/src/line";
import {monotoneX} from "d3-shape/src/curve/monotone";

function getShareGraphData($graph, shares) {
  var res = [],
      lineThickness = 2,
      x = lineThickness,
      width = $graph.width() - lineThickness,
      height = $graph.height() - lineThickness * 2,
      sharesCount,
      step,
      maxVal = 0,
      koef;

  if (!shares) return res;

  sharesCount = shares.length;
  step = width / (sharesCount - 1);

  shares.forEach(function (v, k) {
    var p = (v / shares[sharesCount - 1]) * 1000,
        pl = k > 0 ? (shares[k - 1] / shares[sharesCount - 1]) * 1000 : 0,
        val = (k > 0 ? p - pl : 0);

    res.push({
      x: x,
      y: val
    });

    x += step;
  });

  res.forEach(function (v, k) {
    res[k].y = (k > 0 && k < res.length - 1 ? (res[k - 1].y + res[k].y + res[k + 1].y) / 3 : res[k].y); // middle
    if (maxVal < res[k].y) {
      maxVal = res[k].y;
    }
  });

  koef = height / maxVal;

  res.forEach(function (v, k) {
    res[k].y = res[k].y ? res[k].y * koef - lineThickness : 0;
    res[k].x -= lineThickness / 2;
  });

  return res;
};

export default function shareTrend() {
  var $widgets = $('.trendingBox');
  
  $widgets.each(function(index, widget) {
    var $widget = $(widget),
        $graph = $widget.find('#shares_speed_graph'),
        $path = $widget.find('#shares_line'),
        lineData = getShareGraphData($graph, $graph.data('shares'));
    
    if (!lineData) return;

    var lineFunction = d3Line()
      .x(d => d.x)
      .y(d => $graph.height() - d.y)
      .curve(monotoneX);

    $path.attr('d', lineFunction(lineData));
  });
};